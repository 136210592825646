// @ts-nocheck
/* eslint-disable radix */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line radix
// eslint-disable-next-line jsx-a11y/click-events-have-key-events
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Suspense } from 'react'
import { Link, NavLink, useHistory } from "react-router-dom";
//import NavBar from '../components/NavBar';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'
import "../style.css";
//import Sidebar from '../components/Sidebar';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import SearchIcon from '@mui/icons-material/Search';
import cookies from 'js-cookie'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import Loading from "../images/loading2.svg";
import ClearIcon from '@mui/icons-material/Clear';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import {
  Button,
  CircularProgress,
  Popover,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,

} from '@material-ui/core';
import { basicButtonStyles, tableRowStyles } from 'app/shared/styles';
import { TextCell } from 'app/components/shared/table';

import { default as ReactSelect } from "react-select";
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import { components } from "react-select";
import { Box } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useIntl } from 'react-intl';
import { useGetFilterOrganizations } from 'hooks';
import { useGetFilterGroups } from 'hooks/useGetFilterGroups';
import { useTypedSelector } from 'helpers';
import { useSelector } from 'react-redux';
import { log } from 'console';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { hasWriteAccessToMasterConfigSubSystem } from "selectors";
import StyleIcon from '@mui/icons-material/Style';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { styled } from '@mui/system';
const useStyles = makeStyles(() =>
  createStyles({
    table: { minWidth: 750, overflow: 'scroll', whiteSpace: 'nowrap' },
    addButton: { ...basicButtonStyles },
    row: { ...tableRowStyles },
  })
);

// Custom styled components
const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#F0642C', // Change the color of the indicator line
  },
});

const StyledTab = styled(Tab)(({ theme }) => ({
  color: "#363740",
   fontWeight: 'bold',
   margin:" 0 20px",
   textTransform:"capitalize",
   borderRadius:"10px",
  '&.Mui-selected': {
    color: "#F0642C"
  },
 /* '&:hover': {
    color: "yellow",
  },*/
}));
const OperationsReportsPage: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us' //	ar-eg
  const [organisations, setOrganisations] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let userAuthData = '';
  const authData = localStorage.getItem('authData');
  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    userAuthData = parsedAuthData.userToken;
  }
  const [value, setValue] = React.useState(0);
  const [orgCode, setOrgCode] = useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const clickOrg = (orgCode) => {
    setOrgCode(orgCode)
  };

  const fetchOrganisations = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/faremedia/get_organisations`, {
        headers: {
          'access-token': userAuthData?.access_token,
          uid: userAuthData?.uid,
          client: userAuthData?.client,
        },
      });
      setOrganisations(response.data.data);
    } catch (error) {
      toast.error(`Failed to get organisations [${error}]`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrganisations();
  }, []);
  //not used
  /*const [iFrameError, setIFrameError] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(null);
  let checking_link = (link) => {
  fetch(link, { mode: 'no-cors' })
    .then(() => {
      // If the fetch succeeds, set the iframe's src
      setIframeSrc(link);
      setIFrameError(false);
    })
    .catch((err) => {
      setIframeSrc(link);
      console.error('Error loading URL:', err);
      setIFrameError(true);
    })}*/
  return (
    <div className='dev-contain-all-page-content'>
      <h2 className='header-of-subsystem-page'>
        <EqualizerIcon style={{ margin: "10px  10px 13px 10px", fontSize: "28px" }} />
        {intl.formatMessage({ id: 'OperationsReports' })}
      </h2>
      {userAuthData ?
        <>
          <div className="availabe-org" >

            {
              isLoading ? (

                <div style={{ textAlign: "center", margin: "10px" }}>
                  <CircularProgress style={{ width: "50px" }} />
                </div>

              ) :
                <Box
                  sx={{
                    flexGrow: 1,
                    maxWidth: { xs: 320, sm: 480 },
                    bgcolor: 'background.paper',

                  }}
                >
               {organisations && organisations.length > 0 ? (
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label="scrollable prevent tabs example"
        >
          {organisations.map((organisation, index) => (
            <StyledTab
              key={organisation.id}
              onClick={() => clickOrg(organisation.org_code)}
              label={
                currentLanguageCode === 'en-us'
                  ? organisation.en_name
                  : organisation.ar_name
              }
            />
          ))}
        </StyledTabs>
      ) : (
        <div style={{ textAlign: 'center', padding: '15px' }}>
          {intl.formatMessage({ id: 'somethingWrong' })}
        </div>
      )}
                </Box>
            }
          </div>
          <div className="bi-div">
            {
              isLoading ? (
                <div style={{ textAlign: "center", margin: "10px" }}>
                  <CircularProgress style={{ width: "50px" }} />
                </div>
              ) :
                organisations && organisations?.length > 0  ?
                  <iframe src={
                     `https://bi.avl.${orgCode == null ? organisations[0].org_code : orgCode}.mwasla.tech`
                  } style={{ width: "100%", height: "530px" }} title="Iframe Example"
                  />                  
                  :
                  <h3 style={{ textAlign: "center", margin: "10px" }}>{intl.formatMessage({ id: 'NoDateAvailable' })}</h3>
            }
          </div>
        </> : <h2>
          <center> {intl.formatMessage({ id: 'LoginAgain' })} </center>
        </h2>}
    </div>
  )
}

export default OperationsReportsPage