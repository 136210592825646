import { LOCALES } from './locales-constants';

export default {
  [LOCALES.ARABIC]: {
    operations: 'التشغيل',
    configuration: 'الإعدادات',
    zoning: 'منتجات التعريفة',
    welcome: 'أهلا',
    selectStatement: 'اختر واحد من الأنظمة المتاحة أدناه',
    login: 'تسجيل الدخول',
    logout: 'تسجيل الخروج',
    eazyMobility: 'ايزي موبيليتي',
    routes: 'المسارات',
    englishName: 'الاسم بالانجليزية',
    arabicName: 'الاسم بالعربية',
    code: 'الكود',
    groups: 'المجموعات',
    organization: 'الشركة',
    customerSupport: 'دعم العملاء',
    fareMediaInquery: 'استعلام وسائل دفع التعريفة',
    actions: 'الأدوات',
    addRoute: 'إضافة مسار',
    loginHint: 'برجاء تسجيل الدخول',
    userName: 'اسم المستخدم',
    password: 'كلمة السر',
    addOrganization: 'إضافة شركة',
    pageNotFound: 'صفحة غير موجودة',
    notExist: 'عفوا، هذه الصفحة غير موجودة',
    goToHome: 'الذهاب للصفحة الرئسية',
    status: 'الحالة',
    drivers: 'السائقين',
    admins: 'مستخدمي المنظومة',
    organizations: 'الشركات',
    delete: 'حذف',
    confirmDeleteMessage: 'هل أنت متأكد أنك تريد حذف',
    route: 'مسار',
    cancel: 'إلغاء',
    enabled: 'مفعل',
    update: 'تحديث',
    edit: 'تعديل',
    editRoute: 'تعديل مسار',
    createRoute: 'إضافة مسار جديد',
    create: 'إضافة',
    routeInfo: 'بيانات المسار',
    noGroups: 'لا توجد جروبات معينة لهذا المسار بعد',
    back: 'عودة',
    saveGroups: 'حفظ المجموعات',
    createOrganization: 'إضافة شركة جديدة',
    editOrganization: 'تعديل الشركة',
    oranizationInfo: 'بيانات الشركة',
    name: 'الاسم',
    lastLogin: 'آخر دخول',
    addAdmin: 'إضافة',
    editAdmin: 'تعديل',
    email: 'ايميل',
    phone: 'رقم التليفون',
    firstName: 'الاسم الأول',
    lastName: 'الاسم الأخير',
    repeatPassword: 'إعادة كلمة السر',
    somethingWrong: 'حدث خطأ',
    mwaslatMasrSuperDASH: 'مواصلة.تك  سوبر داش',
    adminInfo: 'بيانات الأدمن',
    masterConfiguration: 'الإعدادات',
    component: 'المكون',
    permissionType: 'نوع الصلاحية',
    save: 'حفظ',
    operationsOrganizationLevel: 'العمليات علي مستوي الشركة',
    requiredValidation: 'هذا الحقل مطلوب',
    passwordMatchValidation: 'إعادة كلمة السر يحب أن تطابق كلمة السر',
    validEmailValidation: 'صيغة الايميل ليست صحيحه',
    addDriver: 'إضافة سائق',
    searchByName: 'البحث عن طريق الإسم',
    driverInfo: 'معلومات السائق',
    nationalId: 'الرقم القومي',
    licenseNumber: 'رقم الرخصة',
    backToDriversList: 'عودة لقائمة السائقين',
    driver: 'السائق',
    import: 'حمل',
    export: 'تنزيل',
    successEditDriverMessage: 'لقد قمت بتحديث بيانات السائق بنجاح!',
    editDriver: 'تعديل سائق',
    organizationId: 'رقم الشركة',
    show: 'اظهر',
    jumpToPage: 'إلى الصفحة',
    addToGroups: 'إضافة إلى مجموعات',
    clearGroups: 'حذف من مجموعات',
    enable: 'تفعيل',
    disable: 'تعطيل',
    add: 'إضافة',
    reset: 'إعادة تعيين',
    filter: 'تصفية',
    filters: 'وسائل التصفية',
    group: 'مجموعة',
    all: 'الكل',
    apply: 'بحث',
    disabled: 'غير مفعل',
    confirmenableMessage: 'هل أنت متأكد من انك تريد تفعيل',
    confirmdisableMessage: 'هل أنت متأكد من انك تريد تعطيل',
    confirmassignMessage:
      'هل أنت متأكد من انك تريد إضافة إلى المجموعات المختاره',
    confirmdeleteMessage: 'هل أنت متأكد من انك تريد حذف',
    confirmclearGroupsMessage: 'هل أنت متأكد من انك تريد حذف من المجموعات',
    confirm: 'تأكيد',
    noOrganization: 'لا توجد شركات',
    selectAll: 'أختر الكل',
    noGroupsFound: 'لا توجد مجموعات',
    successAddDriver: 'تم إضافة سائق بنجاح',
    successAddRoute: 'تم إضافة مسار بنجاح',
    successAddOrganization: 'تم إضافة شركه بنجاح',
    successAddAdmin: 'تم إضافة أدمن بنجاح',
    successEditDriver: 'لقد قمت بتحديث بيانات سائق بنجاح!',
    successEditRoute: 'لقد قمت بتحديث بيانات مسار بنجاح!',
    successEditOrganization: 'لقد قمت بتحديث بيانات شركه بنجاح!',
    successEditAdmin: 'لقد قمت بتحديث بيانات أدمن بنجاح!',
    nationalIdMaxValidationMsg: 'Must be maximum 15 digits',
    digitsOnly: 'Must be only digits',
    phoneMaxValidationMsg: 'Must be maximum 11 digits',
    editProfile: 'تعديل البيانات',
    changePassword: 'تغيير كلمة السر',
    editMyProfile: 'تعديل بياناتي',
    currentPassword: 'كلمه السر الحالية',
    newPassword: 'كلمه السر الجديدة',
    confirmPassword: 'تأكيد كلمه السر الجديدة',
    changePasswordRoles: 'من اجل حماية حسابك، كلمة السر يجب ان تحتوى على:',
    passwordCharacterLength: '6 or more characters',
    passwordValidationLetter: 'At least one letter',
    passwordValidationDigits: 'At least one number',
    successdeletedriver: 'تم حذف السائق بنجاح',
    noResultYet: 'لا توجد نتائج',
    noResultNote: 'سوف يظهر نتائج بمجرد الإضافة',
    addBtn: 'أضف',
    backToOrganizationList: 'عودة لقائمة الشركات',
    backToRoutesList: 'عودة لقائمة المسارات',
    regeneratePassword: 'إعادة تعيين كلمة السر',
    successRegeneratedPassword: 'تم إعادة تعيين كلمة السر بنجاح',
    clearAll: 'حذف الكل',
    restricted: 'محظور',
    fares: 'الأسعار',
    financialControl: 'التحكم المالى',
    digitalSignage: 'التوقيع الرقمى',
    helpDesk: 'الخدمة الفنية',
    editAdminInfo: 'تعديل بيانات الأدمن',
    resetPassword: 'إعادة تعيين كلمة السر',
    theNewPasswordMustHave: 'كلمة السر الجديدة يجب أن تحتوي على',
    sixOrMoreCharacters: 'أكثر من 6 أحرف',
    atLeastOneLetter: 'حرف واحد على الأقل',
    atLeastOneNumber: 'رقم واحد على الأقل',
    grantFullControl: 'إعطاء صلاحية التحكم الكامل',
    denyAllAccess: 'إزالة جميع الصلاحيات',
    editPermissions: 'تعديل الصلاحيات',
    noAccess: 'لا يوجد صلاحيات',
    fullAccess: 'التحكم الكامل',
    readOnly: 'قراءة فقط',
    NoAccess: 'لا يوجد صلاحيات',
    FullAccess: 'التحكم الكامل',
    ReadOnly: 'قراءة فقط',
    adminPermissions: 'صلاحيات الأدمن',
    admin: 'ادمن',
    successUpdatePermissions: 'تم تحديث الصلاحيات بنجاح',
    successGrantFullControl: 'تم إعطاء صلاحية التحكم الكامل بنجاح',
    successDenyAllAccess: 'تم إزالة جميع الصلاحيات بنجاح',
    superAdmin: 'مدير عام',
    superadmin: 'مدير عام',
    tripSales: 'أموال الرحلات',
    addTripSale: 'إضافة أموال الرحلات',
    ticketSerial: 'رقم التذكرة',
    stop: 'التوقف',
    vehicle: 'المركبة',
    timeAndDate: 'الوقت والتاريخ',
    amount: 'المبلغ',
    trip: 'الرحلة',
    fromDate: 'من تاريخ',
    toDate: 'إلى تاريخ',
    stops: 'المحطات',
    price: 'السعر',
    successdeleteaxis: 'تم حذف المحور بنجاح',
    successAddAxis: 'تم إضافة المحور بنجاح',
    successEditAxis: 'تم تحديث المحور بنجاح',
    addAxis: 'إضافة محور',
    editAxis: 'تعديل محور',
    axes: 'المحاور',
    addStop: 'إضافة محطة',
    selectAStop: 'إختر محطة',
    dragRowsToReorder: 'قم بسحب الصفوف لإعادة ترتيبها',
    axis: 'محور',
    priceEGP: 'السعر (جنيه)',
    _stops: 'محطة',
    priceNumberValidation: 'السعر لابد أن يكون أرقام فقط',
    nextPage: 'الصفحة التالية',
    previousPage: 'الصفحة السابقة',
    nogroup: 'لا توجد مجموعات',
    noorganization: 'لا توجد شركات',
    createAdmin: 'إنشاء أدمن',
    passwordRulesNotMatchMessage:
      'كلمة السر يجب أن تحتوي على حرف واحد على الأقل وأكثر من 6 أحرف',
    plateNumber: 'رقم اللوحة',
    addVehicle: 'إضافة المركبة',
    editVehicle: 'تعديل المركبة',
    vehicleInfo: 'معلومات المركبة',
    type: 'النوع',
    vehicles: 'المركبات',
    backToVehiclesList: 'عودة لقائمة المركبات',
    color: 'اللون',
    modelYear: 'سنة الموديل',
    successEditVehicle: 'تم تحديث المركبة بنجاح',
    successAddVehicle: 'تم إضافة المركبة بنجاح',
    successdeletevehicle: 'تم حذف المركبة بنجاح',
    vehicleType: 'نوع المركبة',
    vehicleTypes: 'أنواع المركبات',
    searchByVehicleCodeOrPlateNumber: 'بحث برقم اللوحة أو رقم المركبة',
    vehicleCode: 'رقم المركبة',
    vehicleModelYear: 'سنة موديل المركبة',
    noOrganizationFound: 'لا توجد شركات',
    devices: 'الأجهزة',
    ip: 'الاي بي',
    serial: 'الرقم التسلسلي',
    deviceInfo: 'معلومات الجهاز',
    device: 'الجهاز',
    backToDevicesList: 'عودة لقائمة الأجهزة',
    addDevice: 'إضافة جهاز',
    editDevice: 'تعديل جهاز',
    createDevice: 'إنشاء جهاز',
    successEditDevice: 'تم تحديث الجهاز بنجاح',
    successAddDevice: 'تم إضافة الجهاز بنجاح',
    successdeletedevice: 'تم حذف الجهاز بنجاح',
    deviceType: 'نوع الجهاز',
    deviceTypes: 'أنواع الاجهزة',
    searchByDeviceCodeOrSerial: 'بحث برقم الجهاز أو الرقم التسلسلي',
    devicesManagement: 'إدارة الأجهزة',
    unassigned: 'غير معين',
    vehiclesManagement: 'إدارة المركبات',
    successdeleteroute: 'تم حذف المسار بنجاح',
    adminManagement: 'إدارة الأدمن',
    addGroup: 'إضافة مجموعة',
    successAddGroup: 'تم إضافة المجموعة بنجاح',
    successEditGroup: 'تم تحديث المجموعة بنجاح',
    editGroup: 'تعديل مجموعة',
    successdeletegroup: 'تم حذف المجموعة بنجاح',
    backToGroupsList: 'عودة لقائمة المجموعات',
    groupInfo: 'معلومات المجموعة',
    minLengthOneValidation: 'هذا الحقل يجب ان يحتوى على عنصر واحد على الاقل',
    codeNumberValidation: 'رقم المركبة لابد أن يكون أرقام فقط',
    codeLengthValidation: 'رقم المركبة لابد أن يكون أرقام فقط',
    editStop: 'تعديل محطة',
    longitude: 'خط الطول',
    latitude: 'خط العرض',
    lonNumberValidation: 'خط الطول لابد أن يكون أرقام فقط',
    latNumberValidation: 'خط العرض لابد أن يكون أرقام فقط',
    backToStationsList: 'عودة لقائمة المحطات',
    addStation: 'إضافة محطة',
    editStation: 'تعديل محطة',
    stationInfo: 'معلومات المحطة',
    stations: 'المحطات',
    assignToGroup: 'تعيين إلى مجموعة',
    unAssignFromGroups: 'إلغاء تعيين المجموعات',
    confirmunAssignFromGroupsMessage:
      'هل أنت متأكد من إلغاء تعيين المجموعات من',
    station: 'محطة',
    successEditStation: 'تم تحديث المحطة بنجاح',
    successAddStation: 'تم إضافة المحطة بنجاح',
    successdeletestation: 'تم حذف المحطة بنجاح',
    successAddStop: 'تم إضافة المحطة بنجاح',
    successEditStop: 'تم تحديث المحطة بنجاح',
    successdeletestop: 'تم حذف المحطة بنجاح',
    assigned: 'معين',
    assignDeviceToVehicle: 'تعيين الجهاز إلى المركبة',
    changeDevice_serial: 'تغيير الجهاز {serial}',
    fromVehicle_Code_to: 'من المركبة رقم {from} إلى',
    assignDevice_serial: 'تعيين الجهاز {serial}',
    toVehicleCode: 'إلى المركبة رقم',
    assignedTo_code: 'معين إلى {code}',
    assignDeviceToVehicleMsg:
      'هل انت متأكد من تغيير الجهاز {serial} من كود المركبة {currentVehicleCode} إلى {code}',
    stationList: 'قائمة المحطات',
    vehicleClass: 'فئة المركبة',
    vehicleClasses: 'فئات المركبات',
    addVehicleClass: 'إضافة فئة مركبة',
    editVehicleClass: 'تعديل فئة المركبة',
    vehicleClassInfo: 'معلومات فئة المركبة',
    successAddVehicleClass: 'تم إضافة فئة المركبة بنجاح',
    successEditVehicleClass: 'تم تحديث فئة المركبة بنجاح',
    successdeletevehicleClass: 'تم حذف فئة المركبة بنجاح',
    noVehicleClassFound: 'لا يوجد فئات مركبات',
    noVehicleClassesFound: 'لا يوجد فئات مركبات',
    backToVehicleClassesList: 'عودة لقائمة فئات المركبات',
    avgSpeedNumberValidation: 'السرعة المتوسطة لابد أن يكون أرقام فقط',
    averageSpeed: 'سرعة المتوسطة',
    addVehicleType: 'إضافة نوع مركبة',
    editVehicleType: 'تعديل نوع المركبة',
    vehicleTypeInfo: 'معلومات نوع المركبة',
    successAddVehicleType: 'تم إضافة نوع المركبة بنجاح',
    successEditVehicleType: 'تم تحديث نوع المركبة بنجاح',
    successdeletevehicleType: 'تم حذف نوع المركبة بنجاح',
    noVehicleTypeFound: 'لا يوجد نوع مركبات',
    noVehicleTypesFound: 'لا يوجد نوع مركبات',
    backToVehicleTypesList: 'عودة لقائمة نوع المركبات',
    capacity: 'السعة',
    capacityValidation: 'السعة لابد أن تكون أرقام فقط',
    searchByVehicleCode: 'بحث بكود المركبة',
    vehicletype: 'نوع المركبة',
    vehicleclass: 'فئة المركبة',
    searchByCode: 'بحث بالكود',
    searchByVehicleCodeOrSerial: 'بحث بكود المركبة أو رقم الجهاز',
    deviceIdentifier: 'معرف الجهاز',
    ipIsInvalid: 'عنوان IP غير صحيح',
    changeDeviceOrgMsg:
      'إذا قمت بتغيير الشركة، فسوف يتم إزالة السيارة الحالية من الجهاز',
    changeVehicleOrgMsg:
      'إذا قمت بتغيير الشركة، فسوف يتم إزالة الجهاز من السيارة الحالية',
    cash: 'كاش',
    card: 'بطاقة',
    cashPriceEGP: 'سعر الكاش (جنيه)',
    cardPriceEGP: 'سعر البطاقة (جنيه)',
    successChangePassword: 'تم تعديل كلمة السر بنجاح!',
    importMissingField: 'الحقل "{fieldName}" مفقود',
    groupNotFound: 'المجموعة "{groupName}" غير موجودة لهذه الشركة',
    importErrorAtRecord: 'خطأ في السجل رقم {rowNum}: {error}',
    importRemoteErrorAtRecord: 'خطأ في {rowNum}: {error}',
    importFileTooBig3MB: 'حجم الملف أكبر من 3 ميجابايت',
    vehicleTypeNotFound: 'نوع المركبة غير موجود',
    successImportMessage: 'تم تحميل {count} سجلات بنجاح',
    app_title: "مواصلات مصر",
    language: "اللغة",
    welcome_message: "اهلا بكم",
    days_since_release: "لقد مرت {{number_of_days}} أيام على إصدار هذا الفيديو",
    NoDateAvailable: "لا يوجد بيانات متاحة",
    Routes: "المسارات",
    SearchByName: "البحث بالأسم , الرمز ",
    Show: " عرض   ",
    Login: "تسجيل دخول",
    Logout: "تسجيل خروج",
    LoginAgain: "قم بإعادة تسجيل الدخول ",
    ArabicName: "اللأسم بالعربية ",
    EnglishName: "اللأسم بالأنجليزية ",
    Organization: "الشركة",
    Groups: "المجموعات",
    From: "من",
    Code: " الرمز",
    WelcomeTo: "مرحبا بك ",
    Username: "إسم المستخدم",
    Password: "كلمة السر",
    PleaseLoginToAccessYourAccount: "قم بتسجيل الدخول للوصول لحسابك",
    RouteInfo: "بيانات المسار",
    Axes: "قائمة المحاور",
    AxisInfo: "بيانات المحور",
    StopsInfo: "بيانات المحطات",
    Cash: "نقدي",
    Card: "بطاقة",
    CashPrice: "سعر النقدي",
    CardPrice: "سعر البطاقة",
    Stops: "المحطات",
    Save: "حفظ",
    Edit: "تعديل",
    Stop: "محطة",
    BackToHome: "العودة ",
    PageNotFound: "الصفحة غير موجودة ",
    MakeSureYouHaveOpPermission: "تأكد انك تمتلك بعض  اللأذونات الضرورية في الأنظمة الداخلية للعمليات ",
    YouAreNotAllowedToDoThis: "غير مسموح لك بهذة العملية",
    YouHaveNoAccess: "لا تمتلك الأذونات اللازمة للوصول",
    ObjWSIdNotFound: "هناك مشكلة في الحصول علي اسم الموديول , قد يرجع هذا لحدوث خطأ في استخدام البيانات المتاحة",
    ThisActionNeedToGrantControlForRoutesOfTheOrganisationFirst: "هذة الخطوة تتطلب  اعطاء الصلاحيات لمسارات الشركة اولا",
    ThisActionNeedToDenyControlForFaresOfTheOrganisationFirst: "هذة الخطوة تتطلب  إزالة الصلاحيات لمنتجات تسعير الشركة اولا",
    Grant: "إعطاء",
    Deny: "إذالة",
    Atten: "تنبية",
    CantGetImportantUsedDataFromGroupsApi: " لم نستطيع جلب بيانات المجموعات , قم بالرجوع و إعادة المحاولة",
    NoMessageFound: "جاري التحميل ...",
    someThingWentWrongError: "هناك خطأ ما , قم بالرجوع و إعادة المحاولة",
    noteFaresSybPermissonsChangedToBeLikeRoutesPermissions: "تنبية : أي تغيير في صلاحيات المسارات قد يؤثر علي صلاحيات التسعير",
    errorWhenApplyingPermissionsInGrantFaresReadonlyAccessPleaseTryAgain: "خطأ في إعطاء صلاحيات المشاهدة فقط في التسعير , قم بإعطائها يدويا",
    errorWhenApplyingPermissionsInGrantFaresFullControlAccessPleaseTryAgain: " خطأ في إعطاء  كامل الصلاحيات   في التسعير , قم بإعطائها يدويا",
    errorWhenApplyingPermissionsInRoutesPleaseTryAgain: " خطأ في إعطاء صلاحيات المسارات , قم بإعطائها يدويا",
    AnErrorHappend: "هناك خطأ ما حدث ",
    StopsNumber: "عدد المحطات",
    Back: " الرجوع",
    Filter: "تصفية",
    Apply: "بحث",
    Reset: "إعادة تعيين",
    clearAll2: "رجوع ",
    Delete: "حذف",
    zones: "النطاقات",
    zone: "نطاق",
    Name: "الأسم",
    AddZone: "إضافة نطاق",
    NameCode: " الأسم ( الكود )",
    BaseFare: "   السعر  اللأساسي ( نقدي / بطاقة )",
    CuSym: " ج.م ",
    ZoneInfo: "معلومات النطاق",
    ThisStopIsIn: "هذة المحطة في نطاق  ",
    loginErr1: " يجب أن تدخل أسم المستخدم و كلمة السر",
    loginErr2: "خطأ في أسم المستخدم او كلمة السر",
    loginSuccess: "تم تسجيل الدخول بنجاح",
    Cancel: "تراجع",
    Add: "إضافة",
    RemoveMarker: "حذف",
    WaitMessage: "انتظر حتي يتم تحميل البيانات , إذا استغرق الأمر الكثير من الوقت قم بإعادة تحميل الصفحة",
    SearchByFareMediaSerial: "أدخل الرقم المسلسل لوسيلة دفع التعريفة",
    transID: "رقم العملية",
    ticketPrice: "القيمة  ",
    ticketDate: "التاريخ و الوقت",
    driverName: "أسم السائق",
    driverUserName: " رقم السائق",
    axisName: "أسم المحور",
    deviceSerialNumber: "رقم الجهاز التسلسلي",
    endDate: "إلي",
    startDate: "من",
    transactionsNumber: "مجموع العدد :",
    transactionsTotalAmount: "مجموع النقديات :",
    ResetFilter: "حذف التصفية",
    NoFareMediaExist: "لا يوجد بيانات متاحة لهذا الرقم",
    serial_num: "الرقم المسلسل",
    uid: "الرقم المميز",
    parent_wallet_balance: "الرصيد",
    fare_media_type_name: "النوع",
    is_blacklisted: "محظورة",
    issuer_name: "المصدر",
    account_id: "رقم الحساب",
    init_balance: "الرصيد اللأولي",
    org_name: "الشركة",
    created_at: "تاريخ الصنع",
    updated_at: "أخر تعديل",
    yes: "نعم",
    no: "لا",
    tickets: "التذاكر",
    charging: "الشحن",
    deviceLimitErrorMsg: "عذرا , لقد وصلت إلى الحد الاقصي",
    user_name: "اسم المالك",
    user_phone: "رقم هاتف المالك",
    user_email: "بريد الكتروني المالك",
    expiration_date: "تاريخ اللأنتهاء",
    AFCReports: "تقارير التحصيل",
    CardsReports: "تقارير كروت",
    OperationsReports: "تقارير التشغيل",
    CardsManagement: "إدارة الكروت",
    Merchants: "البائعين",
    MerchantPOS: "أجهزة شحن البائعين",
    ChargingTransactions: "عمليات الشحن",
    AddNewMerchant: "إضافة بائع جديد",
    AddMerchant: "إضافة بائع ",
    MerchantNameEn: "إسم البائع - إنجليزي",
    MerchantNameAr: "إسم البائع - عربي",
    MerchantStatus: "الحالة",
    Adding: "جاري الاضافة ...",
    Update: "تعديل",
    EditMerchant: "تعديل بيانات البائع",
    Updating: "جاري التعديل ...",
    AddNewMerchantPOS: "إضافة جهاز شحن جديد للبائع",
    AddMerchantPOS: "إضافة جهاز شحن ",
    EditMerchantPOS: "تعديل جهاز شحن",
    UserLimit: "الحد المسموح",
    ConfirmPassword: "أعد ادخال كلمة السر",
    SelectMerchant: "اختر بائع ",
    POSName: "اسم الجهاز",
    POSCount: "عدد الاجهزة",
    Of: "من",
    MerchantInfo: "معلومات البائع",
    BackToMerchants: "الرجوع للبائعين",
    MerchantName: "إسم البائع ",
    MerchantPOSName: "الاسم",
    SearchByPOSName: "بحث باسم الجهاز",
    MerchantPOSInfo: "معلومات جهاز الشحن",
    BackToMerchantPOS: "الرجوع لاجهزة شحن البائعين",
    POSStatus: "حالة جهاز الشحن",
    MerStatus: "حالة البائع",
    StartDate: "من تاريخ ",
    EndDate: "الي تاريخ ",
    FareMediaSerial: "الرقم التسلسلي ",
    TransactionNumber: "رقم العملية",
    FareMediaAmount: "رصيد وسيلة الدفع",
    SearchByTransactionNumber: "البحث برقم العملية",
    POSSerialNumber: "رقم جهاز الشحن التسلسلي",
    FareMedia: "وسائل دفع التعريفة",
    AddFareMedia: "اضافة",
    AddNewFareMedia: "اضافة وسيلة جديدة لدفع التعريفة",
    SellingPrice: "سعر البيع",
    DownloadTemplate: "تنزيل القالب ",
    UploadFile: "رفع الملف",
    Uploading: "جاري الرفع ...",
    deviceSN: "رقم الجهاز التسلسلي",
    NewBalance: "الرصيد الجديد",
    SN: "الرقم التسلسلي",
    Vat: "القيمة المضافة",
    TotalFees: "الضريبة",
    PaidAmount: "المبلغ المدفوع",
    SearchBySerial: "البحث بالرقم التسلسلي",
    selling_price: "سعر البيع",
    ar_fare_media_type_name: " النوع - عربي ",
    ar_issuer_name: " المصدر - عربي ",
    ar_org_name: " الشركة - عربي ",
    Reports: "التقارير",
    EditFareMedia:"تعديل وسيلة دفع التعريفة",
     Blacklisted_note:"السبب ",
      is_sold:"مباع",
      issuing_date:"تاريخ الاصدار",
      distribution_date:" تاريخ التوزيع",
      total_charging_amount:"مجموع عمليات الشحن",
      total_ticketing_amount:"مجموع عمليات قطع التذاكر",
      calculated_balance:"الرصيد اعتمادا علي عمليات الشحن و النذاكر",
      addToFareMediaBalance:"اضافة الي رصيد وسيلة دفع الاجرة",
      removeFareMediaBalance:"الخصم من رصيد وسيلة دفع الاجرة",
      BalanceIsCorrect:"الرصيد صحيح",
      BalanceNotes:"ملاحظات علي الرصيد",
       FareMediaInfo:"معلومات وسيلة دفع الاجرة",
        currency:" ج.م ",
        loading:"جاري التحميل ..."
  },
};
